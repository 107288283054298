<template>
  <section>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-1 table"
      item-key="id"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
    >
      <template v-slot:[`item.periodMonth`]={item}>
        <span>
          {{ item.periodMonth }} Bulan
        </span>
        <br>
        <span class="text-caption">
          {{ dateFormat(item.periodStart) }} sd
          {{ dateFormat(item.periodEnd) }}
        </span>
      </template>
      <template v-slot:[`item.monthsExpired`]={item}>
        <span>
          {{ item.monthsExpired }} Bulan
        </span>
      </template>
      <template v-slot:[`item.createdBy`]={item}>
        <span>
          {{ item.createdBy }}
        </span>
        <br>
        <span class="text-caption">
          {{ dateFormat(item.createdAt) }}
        </span>
      </template>
      <template v-slot:[`item.contractStatus`]={item}>
        <span>
          {{ $_strings.contract.status.find((contract) => contract.key === item.contractStatus).value }}
        </span>
      </template>
      <template v-slot:[`item.isActive`]={item}>
        <span>
          {{ item.isActive ? 'Aktif' : 'Tidak Aktif' }}
        </span>
      </template>
      <template v-slot:[`item.approvalStatus`]={item}>
        <v-btn
          text
          class="text-decoration-underline body-2 pt-0 pb-0"
          :class="checkStatus(item.approvalStatus)"
          @click="showApprovalHistory(item)"
        >
          {{$_strings.contract.statusApproval.find((contract) => contract.key === item.approvalStatus ).value }}
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item, index }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on" color="primary"
              dark
              icon
              @click="$router.push(`view/${item.id}`)"
            >
              <v-icon>mdi-file-find</v-icon>
            </v-btn>
          </template>
          <span>{{ $_strings.contract.tooltips.VIEW_DETAIL }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              dark
              icon
              ref="btn"
              :loading="item.isLoadingDownload"
              @click="onButtonDownload(item, index)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>{{ $_strings.contract.tooltips.DOWNLOAD }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on" color="primary"
              dark
              icon
              :loading="item.isLoadingUpload"
              @click="onButtonUpload(item)"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </template>
          <span>{{ $_strings.contract.tooltips.UPLOAD }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on" color="primary"
              dark
              icon
              @click="showApprovalHistory(item)"
            >
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
          </template>
          <span>{{ $_strings.contract.tooltips.DOCUMENT }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          Daftar {{title}}
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
    <DialogApprovalHistory
      ref="dialogApprovalHistory"
    />
    <DialogUpload
      ref="dialogUpload"
      @uploadFile="uploadFile"
    />
  </section>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import DialogApprovalHistory from './Dialog/ApprovalHistory.vue';
import DialogUpload from './Dialog/Upload.vue';

export default {
  props: {
    contractBy: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogApprovalHistory,
    DialogUpload,
  },
  mounted() {
    if (this.contractBy === 'adendum-kontrak' || this.contractBy === 'pengakhiran-kontrak') {
      this.updateHeader();
    }
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchContractList();
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.contract.header.CONTRACT_NUMBER,
          class: 'white--text primary',
          value: 'contractNo',
        },
        {
          text: this.$_strings.contract.header.COMPANY_NAME,
          class: 'white--text primary',
          value: 'companyName',
          width: 200,
        },
        {
          text: this.$_strings.contract.header.DURATION_CONTRACT,
          class: 'white--text primary',
          value: 'periodMonth',
          width: 200,
        },
        {
          text: this.$_strings.contract.header.EXPIRED_CONTRACT,
          class: 'white--text primary',
          value: 'monthsExpired',
          width: 200,
        },
        {
          text: this.$_strings.contract.header.CREATED_BY,
          class: 'white--text primary',
          value: 'createdBy',
          width: 200,
        },
        {
          text: this.$_strings.contract.header.CONTRACT_STATUS,
          class: 'white--text primary',
          value: 'contractStatus',
          width: 200,
        },
        {
          text: this.$_strings.contract.header.STATUS,
          class: 'white--text primary',
          value: 'isActive',
          width: 150,
        },
        {
          text: this.$_strings.contract.header.APPROVAL_STATUS,
          class: 'white--text primary',
          value: 'approvalStatus',
          width: 200,
        },
        {
          text: this.$_strings.contract.header.ACTION,
          class: 'white--text primary',
          value: 'actions',
          sortable: false,
          width: 200,
        },
      ],
      items: [],
      pagination: this.defaultPagination(),
      totalItems: 0,
      itemClicked: {},
    };
  },
  methods: {
    dayjs,
    dateFormat,
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    toggleLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    updateHeader() {
      this.headers.splice(2, 0,
        {
          text: this.$_strings.addendumContract.header.PARTNER,
          value: 'companyType',
          class: 'white--text primary',
          width: 200,
        });
      this.headers.splice(this.headers.findIndex((header) => header.value === 'monthsExpired'), 1);
    },
    getService() {
      if (this.contractBy === 'kontrak-shipper') {
        return this.$_services.contract.fetchContractShipperList;
      }
      if (this.contractBy === 'kontrak-3pl') {
        return this.$_services.contract.fetchContract3PLList;
      }
      if (this.contractBy === 'adendum-kontrak') {
        return this.$_services.contract.fetchAddendumList;
      }
      return this.$_services.contract.fetchTerminationList;
    },
    showApprovalHistory(item) {
      this.$refs.dialogApprovalHistory.dialog = true;
      this.$refs.dialogApprovalHistory.workflowId = item.workflowId;
    },
    onButtonUpload(item) {
      this.itemClicked = item;
      this.$refs.dialogUpload.dialog = true;
    },
    checkStatus(approvalStatus) {
      if (approvalStatus) {
        if (approvalStatus === 'DISETUJUI') {
          return 'green--text';
        }
        if (approvalStatus === 'DITOLAK') {
          return 'red--text';
        }
        return 'orange--text';
      }
    },
    async fetchContractList(event = '') {
      const { search } = this.$route.query;
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      let searchText = '';
      const sort = this.handleSortBy({ sortBy, sortDesc });
      if (event || search) {
        searchText = event.target === undefined ? search : event.target.value;
        if (search !== searchText) {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              search: searchText,
            },
          });
        }
      }
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        companyName: searchText,
        sort,
        ...this.filters,
      };
      try {
        this.toggleLoading(true);
        const result = await this.getService()(this.skipEmptyStringObject(filters));
        this.items = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
    async onButtonDownload(item) {
      this.$set(item, 'isLoadingDownload', true);
      try {
        const result = await this.$_services.contract.getDocumentContract(item.id);
        this.$_services.uploads.downloadFile(result.data.url);
      } finally {
        this.$set(item, 'isLoadingDownload', false);
      }
    },
    async uploadFile(formAttachment, encodedFile) {
      this.$refs.dialogUpload.dialog = false;
      try {
        const { companyId, contractNo } = this.itemClicked;
        this.$set(this.itemClicked, 'isLoadingUpload', true);
        const fileName = `contract_${dayjs().unix()}.${formAttachment.fileType}`;
        const filePath = `contract/${dayjs().get('year')}/${companyId}/${contractNo.split('/')[0]}/`;
        const result = await this.$_services.contract.uploadFile({ encodedFile, fileName, filePath });
        const { url } = result.data;
        const data = {
          document: {
            documentName: formAttachment.name,
            documentStatus: 'FINAL',
            documentType: 'CONTRACT',
            url,
          },
        };
        this.submitAffirmation(data);
      } catch {
        this.$set(this.itemClicked, 'isLoadingUpload', false);
      }
    },
    async submitAffirmation(data) {
      const { id } = this.itemClicked;
      try {
        await this.$_services.contract.submitAffirmation(id, data);
        this.$dialog.notify.success('Berhasil mengunggah dokumen');
      } finally {
        this.$set(this.itemClicked, 'isLoadingUpload', false);
      }
    },
  },
};
</script>

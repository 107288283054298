<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="1024"
      scrollable
    >
      <v-card :loading="isLoading">
        <v-card-title>
          <h4>{{ $_strings.contract.label.APPROVAL_HISTORY }}</h4>
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            item-key="id"
            :items-per-page="100"
          >
            <template v-slot:[`item.number`]={index}>
              <span>{{ index + 1 }}</span>
            </template>
            <template v-slot:[`item.actionDate`]="{item}">
              <span>
                {{ dateFormat(item.actionDate) }}
              </span>
              <br>
              <span class="text-caption">
                {{ dayjs(item.actionDate).format('HH:mm') }}
              </span>
            </template>
            <template v-slot:[`item.notes`]={item}>
              <pre><p class="ma-0">{{ item.notes ? item.notes : '-' }}</p></pre>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '../../../helper/commonHelpers';

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      items: [],
      headers: [
        {
          text: this.$_strings.contract.headerApproval.NO,
          value: 'number',
          sortable: false,
          class: 'white--text primary',
        },
        {
          text: this.$_strings.contract.headerApproval.POSITION,
          value: 'stepName',
          sortable: false,
          class: 'white--text primary',
        },
        {
          text: this.$_strings.contract.headerApproval.NAME,
          value: 'userName',
          sortable: false,
          class: 'white--text primary',
        },
        {
          text: this.$_strings.contract.headerApproval.ACTION,
          value: 'action',
          sortable: false,
          class: 'white--text primary',
        },
        {
          text: this.$_strings.contract.headerApproval.ACTION_TIME,
          value: 'actionDate',
          sortable: false,
          class: 'white--text primary',
        },
        {
          text: this.$_strings.contract.headerApproval.NOTE,
          value: 'notes',
          sortable: false,
          class: 'white--text primary',
        },
      ],
      workflowId: null,
    };
  },
  watch: {
    workflowId(newVal) {
      this.items = [];
      this.getListApporval(newVal);
    },
  },
  methods: {
    dayjs,
    dateFormat,
    async getListApporval(workflowId) {
      try {
        this.isLoading = true;
        const result = await this.$_services.contract.fetchContractApprovalHistory(workflowId);
        this.items = result.data.contents;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalItems,"item-key":"id","footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.periodMonth",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.periodMonth)+" Bulan ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.periodStart))+" sd "+_vm._s(_vm.dateFormat(item.periodEnd))+" ")])]}},{key:"item.monthsExpired",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.monthsExpired)+" Bulan ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.createdBy)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.createdAt))+" ")])]}},{key:"item.contractStatus",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.contract.status.find(function (contract) { return contract.key === item.contractStatus; }).value)+" ")])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.isActive ? 'Aktif' : 'Tidak Aktif')+" ")])]}},{key:"item.approvalStatus",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-decoration-underline body-2 pt-0 pb-0",class:_vm.checkStatus(item.approvalStatus),attrs:{"text":""},on:{"click":function($event){return _vm.showApprovalHistory(item)}}},[_vm._v(" "+_vm._s(_vm.$_strings.contract.statusApproval.find(function (contract) { return contract.key === item.approvalStatus; } ).value)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.$router.push(("view/" + (item.id)))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-find")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.contract.tooltips.VIEW_DETAIL))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({ref:"btn",attrs:{"color":"primary","dark":"","icon":"","loading":item.isLoadingDownload},on:{"click":function($event){return _vm.onButtonDownload(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.contract.tooltips.DOWNLOAD))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":"","loading":item.isLoadingUpload},on:{"click":function($event){return _vm.onButtonUpload(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.contract.tooltips.UPLOAD))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.showApprovalHistory(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.contract.tooltips.DOCUMENT))])])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" Daftar "+_vm._s(_vm.title)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)}),_c('DialogApprovalHistory',{ref:"dialogApprovalHistory"}),_c('DialogUpload',{ref:"dialogUpload",on:{"uploadFile":_vm.uploadFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <!-- HEADER -->
    <HeaderList
      :title="title"
      :filters="filters"
      @fetchContractList="fetching"
    />
    <!-- CONTENT -->
    <v-row>
      <v-col>
        <List
          :key="key_list"
          :title="title"
          :contractBy="contractBy"
          :filters="filters"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import List from './List.vue';
import HeaderList from './HeaderList.vue';

export default {
  components: {
    HeaderList,
    List,
  },
  data() {
    return {
      key_list: 0,
      filters: {
        companyName: this.$route.query.companyName || '',
        companyType: this.$route.query.companyType || '',
        contractNo: this.$route.query.contractNo || '',
        periodMonth: this.$route.query.periodMonth || '',
        isActive: '',
        monthsExpired: this.$route.query.monthsExpired || '',
        approvalStatus: this.$route.query.approvalStatus || '',
        contractStatus: this.$route.query.contractStatus || '',
      },
    };
  },
  computed: {
    title() {
      if (this.contractBy === 'kontrak-shipper') {
        return this.$_strings.contract.label.PAGE_NAME_SHIPPER;
      }
      if (this.contractBy === 'kontrak-3pl') {
        return this.$_strings.contract.label.PAGE_NAME_3PL;
      }
      if (this.contractBy === 'adendum-kontrak') {
        return this.$_strings.addendumContract.label.PAGE_NAME;
      }
      return this.$_strings.terminationContract.label.PAGE_NAME;
    },
    contractBy() {
      const [, , path] = this.$route.path.split('/');
      return path;
    },
  },
  methods: {
    fetching() {
      this.key_list += 1;
    },
  },
};
</script>

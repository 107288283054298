<template>
  <div class="text-center d-flex">
    <v-menu
      bottom
      :close-on-content-click="closeOnContentClick"
      width="300"
      offset-y
      z-index="1"
      allow-overflow
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          hide-details
          height="10"
          class="caption"
          prepend-inner-icon="mdi-filter"
          :placeholder="$_strings.order.FILTER"
          outlined
          dense
          readonly
          v-bind="attrs"
          @click="closeOnContentClick = false"
          v-on="on"
        ></v-text-field>
      </template>
      <v-list
        width="300"
        height="550"
        class="overflow-auto"
      >
        <section v-for="item in filterItems" :key="item.id">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">{{item.title}}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="item.type === 'textfield'">
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                hide-details
                class="caption"
                outlined
                dense
                v-model="form[item.vModel]"
                v-mask="item.mask ? {'alias': item.mask, allowMinus: false, rightAlign: false} : ''"
                clearable
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.type === 'checkbox'" class="pt-0">
            <v-list-item-content class="pt-0">
              <v-row>
                <v-col cols="12" class="pb-0" v-for="checkboxItem in item.child" :key="checkboxItem.id">
                  <v-checkbox
                    hide-details
                    class="caption pt-0 mt-0"
                    v-model="tools[checkboxItem.vModel]"
                    @change="setActiveInactive(checkboxItem.vModel)"
                  >
                    <template v-slot:label>
                      <span class="caption" id="checkboxLabel">{{checkboxItem.title}}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.type === 'approvalStatus'">
            <v-list-item-content class="pt-0 mt-0">
              <section
                v-for="(chip, index) in tools.approvalStatus"
                :key="chip"
                class="mb-2"
              >
                <v-chip
                  close
                  small
                  @click:close="removeApprovalStatus(index)"
                >
                  <strong>{{ approvalStatusItems.find((item) => item.value === chip) && approvalStatusItems.find((item) => item.value === chip).text }}</strong>
                </v-chip>
              </section>
              <v-autocomplete
                hide-details
                :items="approvalStatusItems.filter((item) => !(tools.approvalStatus.find((status) => status === item.value)))"
                @change="addApprovalStatus"
                v-model="tempApprovalStatus"
                class="caption"
                placeholder="Pilih status"
                dense
              ></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.type === 'contractStatus'">
            <v-list-item-content class="pt-0 mt-0">
              <section
                v-for="(chip, index) in tools.contractStatus"
                :key="chip"
                class="mb-2"
              >
                <v-chip
                  close
                  small
                  @click:close="removeContractStatus(index)"
                >
                  <strong>{{ contractStatusItems.find((item) => item.value === chip) && contractStatusItems.find((item) => item.value === chip).text }}</strong>
                </v-chip>
              </section>
              <v-autocomplete
                hide-details
                :items="contractStatusItems.filter((item) => !(tools.contractStatus.find((status) => status === item.value)))"
                @change="addContractStatus"
                v-model="tempContractStatus"
                class="caption"
                placeholder="Pilih status kontrak"
                dense
              ></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section class="pl-2 pr-2">
          <v-btn
            depressed
            color="primary"
            block
            @click="setFilter"
          >
            {{$_strings.common.APPLY}}
          </v-btn>
          <v-btn
            depressed
            class="mt-2 white--text"
            color="red"
            block
            @click="closeOnContentClick = true"
          >
            {{$_strings.common.CLOSE}}
          </v-btn>
        </section>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.typeContract === 'adendum-kontrak' || this.typeContract === 'pengakhiran-kontrak') {
      this.removeFilterItems({
        id: 'monthsExpired',
      });
    }
    if (this.$route.query.isActive === 'true') {
      this.form.isActive = true;
      this.filters.isActive = true;
    }
    if (this.$route.query.isActive === 'false') {
      this.form.isActive = false;
      this.filters.isActive = false;
    }
  },
  data() {
    return {
      filterItems: [
        {
          id: 'contractNo',
          title: 'Nomor Kontrak',
          vModel: 'contractNo',
          type: 'textfield',
        },
        {
          id: 'periodMonth',
          title: 'Durasi Kontrak',
          vModel: 'periodMonth',
          mask: 'integer',
          type: 'textfield',
        },
        {
          id: 'monthsExpired',
          title: 'Durasi Expired Kontrak',
          vModel: 'monthsExpired',
          mask: 'integer',
          type: 'textfield',
        },
        {
          id: 'isActive',
          title: 'Status',
          vModel: 'isActive',
          type: 'checkbox',
          child: [
            {
              id: 'active',
              title: 'Aktif',
              vModel: 'active',
            },
            {
              id: 'inActive',
              title: 'Tidak Aktif',
              vModel: 'inActive',
            },
          ],
        },
        {
          id: 'approvalStatus',
          title: 'Status Persetujuan',
          type: 'approvalStatus',
        },
        {
          id: 'contractStatus',
          title: 'Status Kontrak',
          type: 'contractStatus',
        },
      ],
      form: {
        contractNo: this.$route.query.contractNo || '',
        periodMonth: this.$route.query.periodMonth || '',
        isActive: this.$route.query.isActive || '',
        monthsExpired: this.$route.query.monthsExpired || '',
        approvalStatus: this.$route.query.approvalStatus || '',
      },
      tools: {
        active: this.$route.query.isActive === 'true',
        inActive: this.$route.query.isActive === 'false',
        approvalStatus: this.$route.query.approvalStatus ? this.$route.query.approvalStatus.split(',') : [],
        contractStatus: this.$route.query.contractStatus ? this.$route.query.contractStatus.split(',') : [],
      },
      tempApprovalStatus: '',
      tempContractStatus: '',
      approvalStatusItems: [
        {
          text: 'Menunggu Persetujuan',
          value: 'MENUNGGU_PERSETUJUAN',
        },
        {
          text: 'Disetujui',
          value: 'DISETUJUI',
        },
        {
          text: 'Revisi',
          value: 'REVISI',
        },
        {
          text: 'Ditolak',
          value: 'DITOLAK',
        },
      ],
      contractStatusItems: [
        {
          text: 'Pengajuan Kontrak',
          value: 'CONTRACT',
        },
        {
          text: 'Pengajuan Addendum',
          value: 'ADDENDUM',
        },
        {
          text: 'Pengakhiran Kontrak',
          value: 'FULL_TERMINATION',
        },
        {
          text: 'Pemutusan Kontrak',
          value: 'EARLY_TERMINATION',
        },
      ],
      closeOnContentClick: false,
    };
  },
  computed: {
    typeContract() {
      const [, , path] = this.$route.path.split('/');
      return path;
    },
  },
  methods: {
    removeFilterItems({ id }) {
      this.filterItems.splice(this.filterItems.findIndex((filter) => filter.id === id), 1);
    },
    addApprovalStatus(value) {
      this.tools.approvalStatus.push(value);
      this.form.approvalStatus = this.tools.approvalStatus.toString(',');
    },
    removeApprovalStatus(index) {
      this.tools.approvalStatus.splice(index, 1);
      this.form.approvalStatus = this.tools.approvalStatus.toString(',');
      this.tempApprovalStatus = '';
    },
    addContractStatus(value) {
      this.tools.contractStatus.push(value);
      this.form.contractStatus = this.tools.contractStatus.toString(',');
    },
    removeContractStatus(index) {
      this.tools.contractStatus.splice(index, 1);
      this.form.contractStatus = this.tools.contractStatus.toString(',');
      this.tempContractStatus = '';
    },
    setActiveInactive(value) {
      if (value === 'active' && this.tools.inActive) {
        this.tools.inActive = !this.tools.active;
      }
      if (value === 'inActive' && this.tools.active) this.tools.active = !this.tools.inActive;
      if (!this.tools.inActive && !this.tools.active) {
        this.form.isActive = '';
        return;
      }
      if (this.tools.inActive) {
        this.form.isActive = false;
        return;
      }
      this.form.isActive = true;
    },
    setFilter() {
      this.closeOnContentClick = true;
      this.$emit('setFilter', this.form);
    },
  },
};
</script>
